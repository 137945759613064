<template>
  <span
    class="default-color-statue"
    :class="{
      'black-color-statue': item.etat == 'Créé',
      'green-color': item.etat == 'Payé',
      'red-color montant-bare': item.etat == 'Annulée',
      'color-som-text': item.type === 'separation'
    }"
  >
    <editable-input-frais-unite-th
      :editable="editable"
      :champName="column"
      :item="item"
      :value="item[column]"
      type="text"
      :updateFunction="updateFunction"
      :valueToShow="
        coef == true ? columnCoef : showCoef == true ? columnCoef : column
      "
      :showIconEdit="true"
      :showBlock="showBlock"
      :uniteMontant="uniteMontant"
    />
  </span>
</template>

<script>
import EditableInputFraisUniteTh from './EditableInputFraisUniteTh.vue';
export default {
  props: {
    item: { required: true },
    column: { required: true },
    updateFunction: { required: true },
    coef: { default: false },
    editable: { default: true },
    showCoef: { default: false },
    columnCoef: { default: false },
    showBlock: { default: false },
    uniteMontant: { required: false }
  },
  components: {
    EditableInputFraisUniteTh
  }
};
</script>
